@use "../../main/abstract/variables" as var

.direct-sells

    &__nav
        position: fixed
        inset: 0
        display: grid
        grid-template-rows: auto 1fr
        align-items: center
        height: 100%
        width: 100%
        background-color: var.$color__white
        overflow: auto
        z-index: 105

        &--list
            padding:
                left: var.$padding__m
                right: var.$padding__m
                bottom: var.$padding__m

            & > li
                border-bottom: var.$border__main var.$color__grey

                &:first-child
                    border-top: var.$border__main var.$color__grey

        &--list-item
            position: relative
            display: grid
            grid-template-columns: var.$el-size__m 1fr
            column-gap: var.$gap__s
            padding:
                top: var.$padding__xs
                bottom: var.$padding__xs

            &::before
                content: ""
                position: absolute
                inset: 0 auto 0 0
                display: block
                width: 0
                height: 100%
                background-color: var.$color__light-orange
                transition: width var.$transition__main
                z-index: -1

            &:hover

                &::before
                    width: 100%

                &::active
                    box-shadow: var.$shadow__main-inset

            & > i
                font-size: 1.5rem

            & > .icon__c-motoring
                font-size: 2rem

            & > h3
                padding-right: var.$padding__xxs

    &__swiper-item

        & > a
            display: grid
            grid-template-rows: repeat(2, auto) 1fr

            & > figure
                margin-bottom: var.$gap__m

            & > h3
                margin-bottom: var.$gap__xl

            & > p
                margin-top: auto

    &__nav-hidden
        display: none

    &__info
        margin:
            top: var.$padding__l
            bottom: var.$padding__l
        padding:
            left: var.$padding__s
            right: var.$padding__s

        & > h2
            margin-bottom: var.$padding__m
            text-align: center

        & > p
            padding: var.$padding__s
            box-shadow: var.$shadow__main
