@use "../../main/abstract/variables" as var
@use "../../main/abstract/mixins" as mixin

.verify-password
    $input-width: 2rem
    max-width: calc(#{$input-width} * 6 + #{var.$gap__xs} * 5 + #{var.$padding__s} * 2 + 2px)
    padding:
        left: var.$padding__s
        right: var.$padding__s
        top: var.$padding__m
    @include mixin.centerX

    &__title
        padding:
            left: var.$padding__s
            right: var.$padding__s
        margin-bottom: var.$gap__m

    &__form
        display: grid
        grid-auto-flow: row
        row-gap: var.$gap__xxxl

    &__inputs
        border: var.$border__main var.$color__orange
        padding: var.$padding__m var.$padding__s

    &__resend
        padding:
            left: var.$padding__s
            right: var.$padding__s
        margin-top: var.$gap__m

    &__submit
        box-sizing: border-box
        width: 100%
        padding:
            left: var.$padding__s
            right: var.$padding__s

        & >  button
            width: 100%
            max-width: var.$el-size__xxxl
            @include mixin.centerX
