@use "../../main/abstract/variables" as var
@use "../../main/abstract/mixins" as mixin

.contact
    padding:
        left: var.$padding__m
        right: var.$padding__m

    &__form
        display: grid
        margin-top: var.$padding__m
        row-gap: var.$gap__xl

        &--details
            display: grid
            gap: var.$gap__l

            textarea
                height: var.$el-size__xxl

        &--files
            display: grid
            grid-template-rows: 1fr auto

            & > .input-label__file
                height: max-content

        &--main-error
            transition: height var.$transition__main

            & > p
                margin-top: var.$padding__m

        &--submit
            margin-top: var.$padding__xs

    &__submit-result
        @include mixin.darkTransparentBg
        padding: var.$padding__m

        &--content
            position: relative
            box-sizing: border-box
            background-color: var.$color__white
            width: 100%
            @include mixin.centerX
            @include mixin.centerY
            padding: var.$padding__m
            z-index: 2

        &--content-wrapper
            display: grid
            row-gap: var.$gap__l
            transition: height var.$transition__main

            & > button
                width: 100%
                max-width: var.$el-size__xxl
                margin-left: auto

        &--close-bg
            @include mixin.closeBg
