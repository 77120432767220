@use "../../main/abstract/variables" as var
@use "../../main/abstract/mixins" as mixin

.auction-img
    display: grid
    grid-template-rows: repeat(2, 2fr) var.$padding__xxl

    &__swiper
        grid-column: 1 / 2
        grid-row: 1 / 4
        height: auto
        width: 100%
        padding-bottom: var.$padding__xxl

    &__item
        display: flex
        align-items: center

    &__swiper-controls
        position: absolute
        @include mixin.pin(auto, -1px, 0, -1px)
        box-sizing: border-box
        display: grid
        grid-template-columns: 1fr auto 1fr
        column-gap: var.$gap__l
        align-items: center
        height: var.$padding__xxl
        width: calc(100% + 2px)
        max-width: var.$swiper-item__m
        @include mixin.centerX
        color: var.$color__white
        z-index: 2

        & > .swiper-button-disabled
            cursor: not-allowed
            color: var.$color__dark-grey

            &:hover,
            &:active
                color: var.$color__dark-grey

    &__prev,
    &__next
        font-size: 3.25rem
        transition: color var.$transition__main

        &:hover
            color: var.$color__orange

        &:active
            color: var.$color__grey

    &__prev
        grid-column: 1 / 2
        grid-row: 1 / 2

    &__next
        grid-column: 3 / 4
        grid-row: 1 / 2
        margin-left: auto

    &__pagination
        grid-column: 2 / 3
        grid-row: 1 / 2

        & > .swiper-pagination-current
            font-size: 1.75rem
            font-weight: 600

    & > span
        display: block
        position: relative
        grid-column: 1 / 2
        grid-row: 2 / 4

        &::after
            content: ""
            display: block
            position: absolute
            @include mixin.pin(0, -#{var.$padding__m}, 0, -#{var.$padding__m})
            height: 100%
            width: calc(100% + #{var.$padding__m} * 2)
            background-color: var.$color__black
