@use "../../main/abstract/variables" as var
@use "../abstract/variables" as mobileVar

.submit-result
    position: fixed
    inset: auto 0 mobileVar.$header
    padding: var.$padding__m var.$padding__xxs
    transition: var.$transition__main
    z-index: 1000

    &__content-wrapper
        display: block

    &__content
        display: flex
        box-sizing: border-box
        max-width: max-content
        padding: var.$padding__xs var.$padding__xxs
        box-shadow: var.$shadow__main

    &__success
        background-color: var.$color__black

    &__failure
        background-color: var.$color__red
