@use "../../main/abstract/variables" as var
@use "../../main/abstract/mixins" as mixin

.sign-up
    box-sizing: border-box
    @include mixin.centerX
    width: 100%
    max-width: var.$el-size__queen
    padding:
        left: var.$padding__s
        right: var.$padding__s
        top: var.$padding__m

    & > h1
        padding:
            left: var.$padding__m
            right: var.$padding__m
        margin-bottom: var.$gap__xl

    &__form
        display: grid
        grid-auto-flow: row
        row-gap: var.$gap__xl
        box-sizing: border-box
        height: max-content
        overflow: hidden
        transition: height var.$transition__main

        &--inputs
            display: grid
            grid-auto-flow: row
            row-gap: var.$gap__l
            padding: var.$padding__m
            border: var.$border__main var.$color__orange
            background-color: var.$color__white

        &--enable
            text-align: right
            font-size: .75rem
            transition: var.$transition__main

            &:hover
                color: var.$color__orange

        &--submit
            display: grid
            grid-auto-flow: row
            row-gap: var.$gap__l
            padding:
                left: var.$padding__m
                right: var.$padding__m
            margin-top: var.$gap__xxxl

            & > p
                text-align: center

                & > a
                    text-decoration: underline
                    transition: color var.$transition__main

                    &:hover
                        color: var.$color__orange

                    &:active
                        color: var.$color__grey

            &__prev
                overflow: hidden
                transition: height var.$transition__main

                & > span
                    text-decoration: underline
                    transition: color var.$transition__main

                    &:hover
                        color: var.$color__orange

                    &:active
                        color: var.$color__grey

        &--submit-wrapper
            display: block

            & > button
                width: 100%

        &--terms
            max-width: max-content
            @include mixin.centerX
            padding:
                left: var.$padding__m
                right: var.$padding__m

            & > label
                max-width: max-content

            .errorlist
                margin-top: var.$gap__xs

.step-one
    box-sizing: border-box
    outline: var.$border__main var.$color__orange

    .sign-up__form--inputs
        border-color: transparent
