@use "../../main/abstract/variables" as var
@use "../abstract/variables" as mobileVar
@use "../../main/abstract/mixins" as mixin

.header
    position: fixed
    inset: 0 0 auto 0
    box-sizing: border-box
    z-index: 100

    &__main-list
        position: absolute
        top: 0
        box-sizing: border-box
        display: grid
        grid-template-columns: repeat(2, 1fr)
        column-gap: var.$gap__s
        width: 100%
        padding: var.$padding__xs var.$padding__s
        transition: var.$transition__slow

        &.show-bg
            background-color: var.$color__white

    &__home

        & > a
            display: block

        &--logo
            height: 2.5rem
            width: auto

    &__menu-button
        @include mixin.flex(flex-end, center, row)
        font-size: 1.5rem
        height: 100%
        width: 100%

    &__menu-icon
        position: relative
        @include mixin.flex(center, center, column)
        height: mobileVar.$menu-height
        width: mobileVar.$menu-width

        & > span
            position: absolute
            left: 0
            height: 2px
            background-color: var.$color__black
            transform-origin: center
            transition: var.$transition__main

        & > span:first-child
            width: 100%
            transform: rotate(0) translateY(-0.5rem)

        & > span:nth-child(2)
            width: 100%
            opacity: 1

        & > span:last-child
            width: 100%
            transform: rotate(0) translateY(.5rem)

    &__menu-open

        & > span:first-child
            width: 100%
            transform: rotate(45deg) translateY(0)

        & > span:nth-child(2)
            width: 0
            opacity: 0

        & > span:last-child
            width: 100%
            transform: rotate(-45deg) translateY(0)

    &__sub-list-wrapper
        position: fixed
        @include mixin.pin(0, 0, 0, 0)
        background-color: var.$color__white
        height: 100vh
        width: 100%
        overflow: auto
        z-index: -1

        &::before
            content: ""
            display: block
            position: sticky
            @include mixin.pin(0, 0, auto, 0)
            height: mobileVar.$header
            background-color: var.$color__white
            z-index: 1

        & > nav
            display: flex
            min-height: calc(100% - #{mobileVar.$header})
            box-sizing: border-box
            padding: var.$padding__s

    &__sub-list
        display: grid
        grid-template-columns: 1fr
        width: 100%
        @include mixin.centerY
        border-top: var.$border__main var.$color__grey

        & > li
            display: block
            border-bottom: var.$border__main var.$color__grey

        &--link
            position: relative
            display: block
            padding: var.$padding__xs var.$padding__xxs

            &::before
                content: ""
                display: block
                position: absolute
                height: 100%
                width: 0
                @include mixin.pin(0, auto, 0, 0)
                background-color: var.$color__light-orange
                transition: width var.$transition__main
                z-index: -1

            &:hover::before,
            &.active::before
                width: 100%

            &:active::before
                box-shadow: var.$shadow__small-inset

.header-img
    position: relative
    box-sizing: border-box
    min-height: 20rem
    height: 35vh
    max-height: 30rem
    padding-top: mobileVar.$header

    &::before
        content: ""
        position: absolute
        top: 0
        width: 100%
        height: calc(100% + #{var.$el-size__s})
        @include mixin.bgImg("../img/landing.webp", top center, cover, no-repeat)
        z-index: -1

    &__title
        @include mixin.flex(center, center, row)
        box-sizing: border-box
        height: 100%
        width: 100%
        padding: var.$padding__l var.$padding__s
