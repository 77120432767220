@use "../../main/abstract/variables" as var
@use "../../main/abstract/mixins" as mixin

.message
    @include mixin.darkTransparentBg
    padding: calc(#{var.$padding__s} + #{var.$padding__m} * 2) var.$padding__s

    &__container
        position: relative
        @include mixin.centerX
        @include mixin.centerY
        display: grid
        grid-auto-flow: row
        row-gap: var.$gap__m
        box-sizing: border-box
        width: 100%
        max-width: var.$el-size__queen
        padding: var.$padding__m
        background-color: var.$color__white
        z-index: 2

        & > button
            width: 100%
            max-width: var.$el-size__xxl
            margin:
                top: var.$gap__l
                left: auto

    &__buttons
        display: grid
        grid-template-columns: repeat(auto-fit, 8rem)
        grid-gap: var.$gap__m
        width: 100%
        min-width: max-content
        justify-content: right
        margin:
            left: auto
            top: var.$padding__m

    &__close-bg
        position: absolute
        @include mixin.pin(0, 0, 0, 0)
        height: inherit
        width: inherit
        cursor: url('../img/close.png') 4 12, pointer
        z-index: 1
