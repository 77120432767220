@use "../../main/abstract/variables" as var
@use "../abstract/variables" as mobileVar
@use "../../main/abstract/mixins" as mixin

.profile

    &__resume-wrapper
        overflow: hidden
        transition: height var.$transition__slow

    &__resume
        display: block
        max-width: var.$el-size__queen
        @include mixin.centerX
        padding: var.$padding__xs calc(#{var.$padding__s} + #{var.$padding__xxs}) var.$padding__s

        & > h1
            margin-bottom: var.$gap__l

        &--data
            display: grid
            grid-template-columns: repeat(2, 1fr)
            grid-gap: var.$gap__s

        &--address,
        &--email
            grid-column: 1 / 3

    &__clients
        overflow: hidden
        transition: height var.$transition__slow

        &--header-button-wrapper
            display: block
            padding: var.$padding__s

        &--header-button
            position: relative
            box-sizing: border-box
            display: grid
            grid-template-columns: 1fr auto
            width: 100%
            padding: var.$padding__xs var.$padding__xxs
            text-align: left
            border:
                top: var.$border__main var.$color__grey
                bottom: var.$border__main var.$color__grey
            transition: box-shadow var.$transition__main

            &::before
                position: absolute
                @include mixin.pin(0, auto, 0, 0)
                content: ""
                display: block
                height: 100%
                width: 0
                background-color: var.$color__light-orange
                transition: width var.$transition__main
                z-index: -1

            &:hover::before
                width: 100%

            &:active
                box-shadow: var.$shadow__small-inset

            & > i
                height: 100%
                font-size: .75rem
                transform-origin: center
                transition: transform var.$transition__main

        &--content-wrapper
            padding: var.$padding__xs var.$padding__s var.$padding__s

        &--content
            display: grid
            grid-auto-flow: row
            row-gap: var.$gap__m
            overflow: hidden
            transition: height var.$transition__main

            & > h1
                padding:
                    left: var.$gap__s
                    right: var.$gap__s

                &:first-of-type
                    padding-top: calc(#{var.$gap__s} + #{var.$gap__m})

                &:last-of-type
                    padding-top: var.$gap__s

        &--footer
            position: sticky
            bottom: 0
            overflow: hidden
            transition: var.$transition__slow

            &::before
                position: absolute
                @include mixin.pin(0, 0, 0, 0)
                content: ""
                display: block
                background-color: var.$color__white

            & > span
                display: block
                padding: var.$padding__s var.$padding__s var.$padding__m

        &--add
            position: relative
            display: grid
            grid-template-columns: 1fr auto
            width: 100%
            padding: var.$padding__xs var.$padding__xxs
            background-color: var.$color__pale-orange
            text-transform: uppercase
            transition: var.$transition__main

            &:hover
                background-color: var.$color__orange

            &:active
                box-shadow: var.$shadow__small-inset

            & > span
                padding-right: var.$padding__xxs

            & > i
                position: relative
                height: 100%
                width: 2rem
                margin-left: var.$padding__xxs

                &::after
                    position: absolute
                    @include mixin.pin(-#{var.$padding__xs}, auto, -#{var.$padding__xs}, -#{var.$padding__xxs})
                    content: ""
                    display: block
                    width: 1px
                    height: calc(100% + #{var.$padding__xs} * 2)
                    background-color: var.$color__white

    &__add-new-client
        position: fixed
        @include mixin.pin(0, 0, 0, 0)
        display: flex
        box-sizing: border-box
        height: 100%
        width: 100%
        padding: mobileVar.$header var.$padding__s var.$padding__m
        background-color: var.$color__white
        overflow: auto
        z-index: 100

        &--options
            height: max-content
            width: 100%
            @include mixin.centerY
            padding: unset

        &--option
            position: relative
            display: grid
            grid-template-columns: var.$el-size__s 1fr
            column-gap: var.$gap__s
            align-items: center
            width: 100%
            border-bottom: var.$border__main var.$color__grey

            &:first-child
                border-top: var.$border__main var.$color__grey

        &--option-button
            position: relative
            grid-column: 2 / 3
            grid-row: 1 / 2
            padding:
                top: var.$padding__xs
                bottom: var.$padding__xs
                right: var.$padding__xxs
            text-align: left

            &::before
                position: absolute
                @include mixin.pin(0, 0, 0, calc(-#{var.$el-size__s} - #{var.$gap__s}))
                content: ""
                display: block
                height: 100%
                width: 0
                background-color: var.$color__light-orange
                transition: var.$transition__main
                z-index: -1

            &:hover::before
                width: calc(100% + #{var.$el-size__s} + #{var.$gap__s})

            &:active::before
                box-shadow: var.$shadow__small-inset

        &--option-info
            grid-column: 1 / 2
            grid-row: 1 / 2
            transition: color var.$transition__main
            z-index: 2

            &::before
                position: absolute
                @include mixin.pin(0, auto, 0, 0)
                content: ""
                display: block
                height: 100%
                width: 0
                background-color: var.$color__black
                transition: width var.$transition__main
                z-index: -1

            &:hover
                color: var.$color__orange

            &:active
                color: var.$color__medium-grey

        &--option-details
            position: relative
            grid-column: 1 / 3
            grid-row: 1 / 2
            display: grid
            align-items: center
            box-sizing: border-box
            margin-left: calc(#{var.$el-size__s} + #{var.$gap__s})
            padding-right: var.$padding__xxs
            color: var.$color__white
            text-align: left
            overflow: hidden
            transition: var.$transition__main
            z-index: 2

            & > p
                margin:
                    top: var.$padding__xxxs
                    bottom: var.$padding__xxxs

            &:hover
                color: var.$color__orange

            &:active
                color: var.$color__grey

        &--close
            position: fixed
            @include mixin.pin(0, 0, auto, 0)
            box-sizing: border-box
            width: 100%
            padding: var.$padding__xs var.$padding__s
            background-color: var.$color__white

            & > button
                width: 100%

    &__client
        position: relative
        padding: var.$padding__s var.$padding__xxs var.$padding__xxs
        border: var.$border__main var.$color__orange

        &:first-child
            margin-top: var.$padding__m

        &--header,
        &--data
            display: grid
            grid-template-columns: repeat(2, 1fr)
            column-gap: var.$gap__xs

        &--header
            border-bottom: var.$border__main var.$color__grey
            padding-bottom: var.$padding__xxxs

        &--content
            display: grid
            grid-auto-flow: row
            row-gap: var.$gap__l
            margin-top: var.$padding__xs

        &--data
            row-gap: var.$gap__xs

        &--data-phone
            grid-column: 2 / 3

        &--delete
            $button-size: 1.5rem
            position: absolute
            @include mixin.pin(.5rem, .5rem, auto, auto)
            @include mixin.flex(center, center, row)
            height: $button-size
            width: $button-size
            border: var.$border__main var.$color__grey
            border-radius: 50%
            transition: border-color var.$transition__main

            &::before,
            &::after
                position: absolute
                content: ""
                display: flex
                width: .75rem
                height: 1px
                background-color: var.$color__grey
                transform-origin: center
                transition: background-color var.$transition__main

            &::before
                transform: rotate(45deg)

            &::after
                transform: rotate(-45deg)

            &:hover
                border-color: var.$color__red

                &::before,
                &::after
                    background-color: var.$color__red

    &__multi-form
        position: relative
        height: 100%

    &__add-client-form
        position: fixed
        @include mixin.pin(0, 0, 0, 0)
        box-sizing: border-box
        height: 100vh
        width: 100vw
        padding-top: mobileVar.$header
        background-color: var.$color__white
        overflow: auto
        z-index: 100

        &--transition
            position: absolute
            box-sizing: border-box
            height: 100%
            width: 100%
            transition: var.$transition__main

        &--container
            padding:
                left: var.$padding__s
                right: var.$padding__s
                bottom: var.$padding__l

            & > h1
                margin-bottom: var.$padding__s
                padding:
                    left: var.$padding__xs
                    right: var.$padding__xs

        &--fields-simple,
        &--fields-multi-wrapper
            border: var.$border__main var.$color__orange
            padding: var.$padding__s var.$padding__xs

        &--fields-simple,
        &--fields-multi
            display: grid
            grid-auto-flow: row
            row-gap: var.$gap__s
            align-items: end

            @media screen and (min-width: mobileVar.$breakpoint__s)
                grid-auto-flow: initial
                grid-template-columns: repeat(2, 1fr)
                column-gap: var.$gap__m

        &--fields-simple

            @media screen and (min-width: mobileVar.$breakpoint__s)
                grid-template-columns: repeat(2, 1fr)

        &--fields-simple .select-main__content-wrapper > .input-label__main > span:last-child
            height: var.$button-size__s

        &--fields-multi
            padding: 1px

            & > input
                display: none
                position: absolute

            @media screen and (min-width: mobileVar.$breakpoint__s)
                grid-template-columns: repeat(2, 1fr)

                & > .profile-company:first-of-type
                    grid-column: 1 / 3

        @media screen and (min-width: mobileVar.$breakpoint__s)

            &--field-multi-person-name
                grid-column: 1 / 3

            &--field-multi-company-name,
            &--field-multi-surname
                grid-column: 1 / 2

            &--field-multi-percent
                grid-column: 2 / 3

            &--field-multi-id
                grid-column: 1 / 2

            &--field-multi-phone
                grid-column: 2 / 3

        &--fields-multi-transition
            display: block
            box-sizing: border-box
            overflow: hidden
            transition: height .3s ease-in-out

        &--fields-header
            padding:
                left: var.$padding__xxs
                right: var.$padding__xxs
                bottom: var.$padding__xxxs
            margin-bottom: var.$padding__xs
            border-bottom: var.$border__main var.$color__grey

            @media screen and (min-width: mobileVar.$breakpoint__s)
                grid-column: 1 / 3

            & > h2
                margin-bottom: var.$gap__xxs

        &--fields-multi-header
            display: grid
            grid-template-columns: 1fr
            grid-gap: var.$gap__m
            margin-bottom: var.$gap__l

            @media screen and (min-width: mobileVar.$breakpoint__s)
                grid-template-columns: 1fr auto

            &--name
                padding:
                    left: var.$padding__xxs
                    right: var.$padding__xxs

                @media screen and (min-width: mobileVar.$breakpoint__s)
                    padding-right: unset

                & > h3
                    margin-bottom: var.$gap__xxs

            &--bidder-num
                display: grid
                grid-template-columns: 1fr var.$el-size__l
                column-gap: var.$gap__s
                align-items: center
                padding-left: var.$padding__xxs

                @media screen and (min-width: mobileVar.$breakpoint__s)
                    padding-left: unset

                & > h3
                    text-align: right

                .input-label__main

                    & > input
                        text-align: center
                        width: calc(100% - #{var.$padding__xxxs} * 2)
                        margin: var.$padding__xxxs
                        outline-offset: var.$padding__xxxs

                    & > span:last-child
                        height: 2.25rem

                .list__main
                    padding: var.$padding__xxxs

                .list__main--item > span
                    padding: var.$gap__xxs
                    text-align: center

            & > nav
                box-sizing: border-box
                position: relative
                left: -#{var.$padding__xs}
                width: calc(100% + #{var.$padding__xs} * 2)
                padding:
                    left: calc(#{var.$padding__xs} + #{var.$padding__xxs})
                    right: calc(#{var.$padding__xs} + #{var.$padding__xxs})
                    bottom: 1px

                &::after
                    content: ""
                    display: block
                    position: absolute
                    left: var.$padding__xs
                    width: calc(100% - #{var.$padding__xs} * 2)
                    height: 1px
                    background-color: var.$color__grey
                    z-index: 100

                & > ul
                    box-sizing: border-box

                .swiper-slide
                    width: max-content

                @media screen and (min-width: mobileVar.$breakpoint__s)
                    grid-column: 1 / 3

        &--button
            position: relative
            padding: var.$padding__xxs
            transition: color var.$transition__main

            &::after
                content: ""
                display: block
                position: absolute
                bottom: 0
                left: 0
                height: 3px
                width: 0
                background-color: var.$color__orange
                transition: width var.$transition__main

            &:hover
                color: var.$color__orange

                &::after
                    width: 100%

        &--active-button
            color: var.$color__orange

            &::after
                width: 100%

        &--swiper-next,
        &--swiper-prev
            position: absolute
            top: 0
            @include mixin.flex(center, center, row)
            height: 100%
            width: calc(#{var.$padding__xs} + #{var.$padding__xxs} + 1px)
            font-size: .5rem
            background-color: var.$color__white
            z-index: 1
            transition: var.$transition__main

            &:hover
                color: var.$color__orange

            & > i
                transform-origin: center

        &--swiper-next
            right: 0

            & > i
                transform: rotate(-90deg)

        &--swiper-prev
            left: 0

            & > i
                transform: rotate(90deg)

        &--swiper-next.swiper-button-disabled,
        &--swiper-prev.swiper-button-disabled
            opacity: 0

        &--terms
            margin:
                top: var.$padding__s
                bottom: var.$padding__l

        &--terms-wrapper
            position: relative
            display: grid
            grid-template-columns: auto 1fr
            column-gap: var.$gap__m
            align-items: start
            padding:
                left: #{var.$padding__xs}
                right: #{var.$padding__xs}

            & > input
                position: absolute
                @include mixin.pin(0, 0, 0, 0)
                height: 100%
                width: 100%
                z-index: 3

                &:checked ~ span

                    .icon__box
                        opacity: 0

                    .icon__box-checked
                        opacity: 1

            &:hover > span
                color: var.$color__orange

            .input-error__wrapper
                grid-column: 1 / 3
                text-align: left

                & > span
                    display: inline-block
                    padding-top: var.$gap__xs

        // TODO clear when django forms will be finished
        &--fields-wrapper
            border: var.$border__main var.$color__orange
            padding: var.$padding__s var.$padding__xs

        &--fields
            display: grid
            grid-auto-flow: row
            row-gap: var.$gap__s

            @media screen and (min-width: mobileVar.$breakpoint__s)
                grid-auto-flow: initial
                grid-template-columns: repeat(2, 1fr)
                column-gap: var.$gap__m

                .profile__add-client-form--field-name
                    grid-column: 1 / 3

        &--field-name

            @media screen and (min-width: mobileVar.$breakpoint__s)
                grid-column: 1 / 3

        &--submit-wrapper
            display: grid
            grid-template-columns: 1fr
            row-gap: var.$gap__l
            padding-bottom: var.$padding__l

            @media screen and (min-width: mobileVar.$breakpoint__s)
                grid-template-columns: repeat(2, 1fr)
                column-gap: var.$gap__m

                & > button:first-child
                    margin-right: unset

                &  > button:last-child
                    margin-left: unset

        &--submit,
        &--next-multi
            width: 100%
            max-width: var.$el-size__xxxl
            @include mixin.centerX

        &--next-multi
            margin-top: var.$padding__m

        &--close
            position: absolute
            @include mixin.pin(0, 0, auto, 0)
            box-sizing: border-box
            width: 100%
            padding: var.$padding__xs var.$padding__s
            background-color: var.$color__white

            & > span
                @include mixin.buttonClose

        &--main-error
            text-align: center
            overflow: hidden
            transition: height var.$transition__main

            & > p
                margin-bottom: var.$gap__xl

    .select-main__content-wrapper
        height: auto

        & > .input-label__main > span:last-child
            bottom: 0
            transition: transform var.$transition__main

        &.hover > div
            max-height: calc(4.5rem * 2)

    &__auctions
        overflow: hidden
        transition: height var.$transition__slow

        &--header
            text-align: center
            margin:
                top: var.$padding__l
                bottom: var.$padding__m
            padding:
                left: var.$padding__xs
                right: var.$padding__xs

.add-slide-up
    @include mixin.animation(slideUpM, .3s, forwards)

.add-slide-down
    @include mixin.animation(slideDownM, .3s, forwards)

.info-open
    color: var.$color__white

    &::before
        width: 100%
