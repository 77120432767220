@use "../../main/abstract/variables" as var

/* Add multi clients, extra translate size requered */
@keyframes closeMultiFormToLeft
    from
        transform: translateX(0)
    to
        transform: translateX(calc(-100% - #{var.$padding__s} * 2))

@keyframes closeMultiFormToRight
    from
        transform: translateX(0)
    to
        transform: translateX(calc(100% + #{var.$padding__s} * 2))

@keyframes openMultiFormFromLeft
    from
        transform: translateX(calc(-100% - #{var.$padding__s} * 2))
    to
        transform: translateX(0)

@keyframes openMultiFormFromRight
    from
        transform: translateX(calc(100% + #{var.$padding__s} * 2))
    to
        transform: translateX(0)

/* Slide container transition */
@keyframes slideUpM
    from
        transform: translateY(100%)
    to
        transform: translateY(0)

@keyframes slideDownM
    from
        transform: translateY(0)
    to
        transform: translateY(100%)

$add-client__button-height: 7rem

@keyframes addClientsUp
    from
        max-height: 0
        opacity: 0
    to
        max-height: $add-client__button-height
        opacity: 1

@keyframes addClientsDown
    from
        max-height: $add-client__button-height
        opacity: 1
    100%
        max-height: 0
        opacity: 0
