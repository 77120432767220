@use "../../main/abstract/variables" as var
@use "../abstract/variables" as mobileVar
@use "../../main/abstract/mixins" as mixin

.home

    &__filter
        $padding__inputs: calc(#{var.$padding__xs} - 2px)
        padding:
            left: var.$padding__s
            right: var.$padding__s

        &--button
            position: relative
            display: grid
            grid-template-columns: var.$el-size__xl 1fr
            height: var.$el-size__xl
            width: 100%
            max-width: var.$el-size__xxxl
            @include mixin.centerX
            box-shadow: var.$shadow__main
            transition: box-shadow var.$transition__main

            &::before
                content: ""
                display: block
                position: absolute
                @include mixin.pin(0, 0, 0, 0)
                height: 100%
                width: 100%
                transition: box-shadow var.$transition__main

            &:hover

                .home__filter--button-icon
                    background-color: var.$color__black
                    color: var.$color__white

                .home__filter--button-text
                    background-color: var.$color__orange

            &:active
                box-shadow: var.$shadow__main-hidden

                .home__filter--button-icon
                    background-color: var.$color__dark-grey
                    color: var.$color__white

                &::before
                    box-shadow: var.$shadow__main-inset

        &--button-icon,
        &--button-text
            @include mixin.flex(center, center, row)
            height: inherit

        &--button-icon
            background-color: var.$color__white
            font-size: 1.5rem
            transition: var.$transition__main

        &--button-text
            background-color: var.$color__pale-orange
            text-transform: uppercase
            transition: var.$transition__main

        &--menu
            position: fixed
            @include mixin.pin(0, 0, 0, 0)
            display: grid
            grid-template-columns: 1fr
            grid-template-rows: auto 1fr
            width: 100%
            background-color: var.$color__white
            overflow: auto
            z-index: 100

            & > form
                position: relative
                height: 100%

        &--menu-header
            position: sticky
            top: 0
            display: grid
            grid-template-columns: 1fr auto
            align-items: center
            height: mobileVar.$header
            width: 100%
            background-color: var.$color__white
            padding:
                left: var.$padding__s
                right: var.$padding__s
            text-align: left
            z-index: 5

            & > span
                position: relative
                @include mixin.flex(center, center, column)
                height: mobileVar.$menu-height
                width: mobileVar.$menu-width

                & > span
                    position: absolute
                    left: 0
                    height: 2px
                    background-color: var.$color__black
                    transform-origin: center
                    transition: var.$transition__main

                & > span:first-child
                    width: 100%
                    transform: rotate(45deg) translateY(0)

                & > span:last-child
                    width: 100%
                    transform: rotate(-45deg) translateY(0)

        &--menu-list-container
            position: absolute
            display: grid
            grid-template-columns: 1fr
            grid-template-rows: auto 1fr auto
            box-sizing: border-box
            height: max-content
            width: 100%
            padding:
                left: var.$padding__s
                right: var.$padding__s

            & > ul
                padding-bottom: var.$padding__m

        &--menu-search-field
            display: grid
            grid-template-columns: var.$el-size__m 1fr
            padding:
                top: calc(#{var.$padding__xxs} + #{var.$padding__s})
                bottom: calc(#{var.$padding__xxs} + #{var.$padding__s})
            border-bottom: var.$border__main var.$color__grey

            & > span
                position: relative
                grid-column: 1 / 2
                grid-row: 1 / 2
                @include mixin.flex(center, center, row)
                font-size: 1rem
                z-index: 2

            & > input
                grid-column: 1 / 3
                grid-row: 1 / 2
                width: calc(100% - #{var.$padding__xs} * 2)
                @include mixin.centerX
                padding-left: calc(#{var.$el-size__m} - #{var.$padding__xs})
                outline: var.$border__main var.$color__grey
                outline-offset: var.$padding__xs
                font-size: 1rem
                transition: outline-color var.$transition__main

                &:focus
                    outline-color: var.$color__orange

        &--menu-list-item
            border-bottom: var.$border__main var.$color__grey

            & > button
                display: grid
                grid-template-columns: var.$el-size__m 1fr
                height: var.$el-size__l
                width: 100%
                padding-right: var.$padding__xs
                transition: var.$transition__main

                &:hover
                    background-color: var.$color__black
                    color: var.$color__white

                &:active
                    background-color: var.$color__dark-grey
                    box-shadow: var.$shadow__main-inset-dark

                &:disabled
                    opacity: .25
                    cursor: not-allowed

                &:disabled:hover,
                &:disabled:active
                    background-color: var.$color__white
                    color: var.$color__black
                    box-shadow: unset

                & > span
                    @include mixin.flex(center, center, row)
                    height: 100%
                    font-size: 1.1rem

                &:last-child > span
                    font-size: 1.1rem

                & > p
                    text-align: left
                    @include mixin.centerY

        &--menu-sublist-container
            position: absolute
            display: grid
            grid-template-columns: 1fr
            grid-template-rows: repeat(2, auto) 1fr
            height: 100%
            width: 100%
            background-color: var.$color__white
            overflow: hidden

            & > ul
                height: 100%
                box-sizing: border-box
                padding:
                    left: var.$padding__s
                    right: var.$padding__s
                    bottom: var.$padding__xs
                overflow: auto

                &:nth-child(2)
                    height: calc(100% - #{var.$padding__xs})
                    margin-top: var.$padding__xs

        &--menu-sublist-return
            display: grid
            grid-template-columns: repeat(2, auto) 1fr
            column-gap: var.$gap__m
            height: var.$el-size__l
            padding:
                left: calc(#{var.$padding__s} + #{var.$padding__xs})
                right: calc(#{var.$padding__s} + #{var.$padding__xs})
            background-color: var.$color__light-grey
            transition: var.$transition__main

            & > span
                @include mixin.flex(center, center, row)
                height: inherit

                &:first-of-type
                    font-size: 1rem

                &:last-of-type
                    font-size: 1.25rem

            & > h3
                text-align: left
                @include mixin.centerY

            &:hover
                background-color: var.$color__black
                color: var.$color__white

            &:active
                background-color: var.$color__dark-grey
                box-shadow: var.$shadow__main-inset-dark

        &--menu-sublist-search
            @include mixin.flex(center, center, row)
            padding:
                top: calc(#{var.$padding__xxs} + #{var.$padding__xs})
                bottom: calc(#{var.$padding__xxs} + #{var.$padding__xs})
            margin:
                left: var.$padding__s
                right: var.$padding__s

            & > input
                grid-column: 1 / 3
                grid-row: 1 / 2
                width: calc(100% - #{var.$padding__xs} * 2)
                outline: var.$border__main var.$color__grey
                outline-offset: $padding__inputs
                transition: outline-color var.$transition__main

                &:focus
                    outline-color: var.$color__orange

        &--menu-sublist-prices
            display: grid
            grid-auto-flow: row
            row-gap: var.$gap__s
            margin-top: var.$padding__xs

            & > label
                padding:
                    top: $padding__inputs
                    bottom: $padding__inputs

        &--menu-sublist-item-container
            border-bottom: var.$border__main var.$color__grey

        &--menu-sublist-item
            position: relative
            display: block
            height: var.$el-size__l

            &::before
                content: ""
                display: block
                position: absolute
                @include mixin.pin(0, 0, 0, 0)
                height: 100%
                width: 0
                background-color: var.$color__light-orange
                transition: var.$transition__main

            &:hover::before
                width: 100%

            &:active::before
                box-shadow: var.$shadow__main-inset

            & > input
                position: absolute
                height: 100%
                width: 100%
                opacity: 0

            & > span
                position: relative
                @include mixin.flex(flex-start, center, row)
                height: inherit
                padding:
                    left: var.$padding__xs
                    right: var.$padding__xs
                transition: background-color var.$transition__main
                z-index: 2

            & > input:checked ~ span
                background-color: var.$color__light-orange

        &--menu-submit
            position: sticky
            bottom: 0
            padding-bottom: var.$padding__m

            & > button
                width: 100%
                max-width: var.$el-size__xxxl
                @include mixin.centerX

    &__advice
        $pagination: .6rem
        padding-top: var.$padding__l
        padding:
            left: var.$padding__s
            right: var.$padding__s
        overflow: hidden

        &--item
            box-sizing: border-box
            height: 100%
            border: var.$border__thick var.$color__grey
            padding: var.$padding__xs
            background-color: var.$color__white

        &--downloads
            margin-top: var.$padding__xs

        &--pagination
            @include mixin.centerX
            @include mixin.flex(center, cernter, row)
            flex-wrap: wrap
            margin-top: var.$padding__s

            & > span
                display: block
                height: $pagination
                width: $pagination
                border-radius: 50%
                background-color: var.$color__grey
                margin: var.$gap__xxs !important
                opacity: 1
                transition: background-color var.$transition__main

                &:hover
                    background-color: var.$color__pale-orange

            .swiper-pagination-bullet-active
                background-color: var.$color__dark-grey

                &:hover
                    background-color: var.$color__dark-grey

        .swiper-horizontal > .swiper-pagination-bullets
            width: max-content
            max-width: 100%

        .swiper-cube .swiper-slide
            height: auto

    &__properties
        margin:
            top: var.$padding__l
            bottom: var.$padding__xl

        &--header
            padding:
                left: var.$padding__s
                right: var.$padding__s
            text-align: center

        &--header-buttons
            @include mixin.flex(center, center, row)
            flex-wrap: wrap
            max-width: max-content
            @include mixin.centerX
            margin-top: var.$gap__l

            & > button
                position: relative
                @include mixin.flex(center, center, row)
                width: max-content
                padding-bottom: var.$gap__xxs
                margin:
                    left: var.$gap__s
                    right: var.$gap__s
                    bottom: var.$gap__s
                transition: color var.$transition__main

                &::after
                    position: absolute
                    bottom: 0
                    content: ""
                    display: block
                    height: 3px
                    width: 0
                    background-color: var.$color__orange
                    transition: width var.$transition__main

                &:hover::after,
                &:disabled::after
                    width: 103%

                &:active
                    color: var.$color__orange

                &:disabled
                    cursor: not-allowed
                    color: var.$color__black

    &__direct-sells,
    &__history
        display: grid
        grid-template-rows: var.$el-size__xxxl var.$el-size__xl auto
        margin:
            top: var.$padding__xl
            bottom: var.$padding__xl

        &::before
            content: ""
            display: block
            grid-column: 1 / 2
            grid-row: 2 / 4
            background-color: var.$color__black

        &--header
            grid-column: 1 / 2
            grid-row: 3 / 4
            display: grid
            grid-template-rows: repeat(2, auto)
            row-gap: var.$padding__l
            padding: var.$padding__l var.$padding__m

            & > h2,
            & > a
                grid-column: 1 / 2

            & > h2
                color: var.$color__white
                text-align: center

            & > a
                width: 100%
                max-width: calc(#{var.$el-size__xxl} * 2)
                @include mixin.centerX

        &--figure
            grid-column: 1 / 2
            grid-row: 1 / 3
            padding:
                left: var.$padding__m
                right: var.$padding__m

            & > figure
                display: flex
                height: 100%
                width: 100%

                & > img
                    height: inherit
                    width: inherit
                    object-fit: cover
                    object-position: center

    &__how-we-work
        padding:
            left: var.$padding__s
            right: var.$padding__s

        & > h2
            text-align: center
            margin-bottom: var.$padding__m

        & > p
            margin-bottom: var.$gap__s
