@use "../../main/abstract/variables" as var
@use "../abstract/variables" as mobileVar

.history

    &__statistics
        padding:
            top: var.$padding__l
            left: var.$padding__s
            right: var.$padding__s

        &--header
            display: grid
            row-gap: var.$gap__l
            text-align: center

        &--chart
            $number-width: 5.5rem
            $number-width-small: 5rem

            & > ol
                padding: unset
                list-style: none
                margin-top: var.$padding__l

            &__item,
            &__total
                position: relative
                display: grid
                grid-template-columns: repeat(2, 1fr)
                column-gap: var.$gap__xl
                padding-top: var.$padding__xs

                @media screen and (max-width: mobileVar.$breakpoint__m)
                    grid-template-columns: 2fr 4fr

                    .title-l
                        font-size: 2.75rem

            &__item:first-child
                padding-top: unset

            &__item:not(.small)
                grid-template-areas: "block info"

            &__item.small
                grid-template-areas: "block info" ".small"
                row-gap: var.$gap__xxs

            &__item
                padding-bottom: var.$padding__xs

                & > span
                    display: block
                    grid-area: block
                    justify-self: right
                    background-color: var.$color__orange
                    font-size: 3rem
                    color: var.$color__orange
                    overflow: hidden
                    transition: width var.$transition__slow

                &:not(.small)::after
                    content: ""
                    display: block
                    position: absolute
                    bottom: 0
                    grid-column: 2 / 3
                    height: 1px
                    width: 100%
                    background-color: var.$color__black

                & > p,
                & > small
                    display: grid
                    grid-template-columns: $number-width 1fr
                    column-gap: var.$gap__s

                    @media screen and (max-width: mobileVar.$breakpoint__m)
                        grid-template-columns: $number-width-small 1fr

                & > p
                    grid-area: info
                    align-items: last baseline

                    & > strong
                        line-height: 1.25

                & > small
                    grid-area: small
                    word-wrap: break-word
                    overflow-wrap: break-word
                    -webkit-hyphens: auto
                    -moz-hyphens: auto
                    hyphens: auto

                    &::before
                        content: ""
                        display: block
                        width: 100%

            &__total
                grid-template-areas: ".total"

                & > p
                    grid-area: total
                    display: grid
                    grid-template-columns: $number-width 1fr
                    column-gap: var.$gap__s
                    align-items: baseline

                    @media screen and (max-width: mobileVar.$breakpoint__m)
                        grid-template-columns: $number-width-small 1fr

                    & > strong
                        line-height: 1.25

                &::before
                    content: ""
                    position: absolute
                    top: 0
                    display: block
                    height: 2px
                    width: 100%
                    background-color: var.$color__black

        &--download
            display: grid
            grid-template-columns: auto 1fr auto
            column-gap: var.$gap__m
            align-items: center
            margin-top: var.$padding__xxl
            padding: var.$padding__xs
            border:
                top: var.$border__main var.$color__black
                bottom: var.$border__main var.$color__black
            transition: var.$transition__main

            &:hover,
            &:active
                background-color: var.$color__light-orange

                & > .button__solid-blue
                    background-color: var.$color__orange

            &:active
                box-shadow: var.$shadow__main-inset

                & > .button__solid-blue
                    box-shadow: var.$shadow__main-inset

            @media screen and (max-width: mobileVar.$breakpoint__m)
                padding:
                    top: var.$padding__s
                    bottom: var.$padding__s

                &:hover,
                &:active
                    background-color: var.$color__orange

                & > .button__solid-blue
                    display: none

            & > i
                font-size: 2rem

    &__best-auctions
        margin:
            top: var.$padding__xxl
            bottom: var.$padding__xl

        &--header
            padding:
                left: var.$padding__s
                right: var.$padding__s
            margin-bottom: var.$padding__m
            text-align: center

        &--details
            $nav-button-size: 3.25rem
            position: fixed
            inset: 0
            height: 100%
            width: 100%
            margin: unset
            border: unset
            padding: unset
            z-index: 100

            &__swiper
                box-sizing: border-box
                position: relative
                display: grid
                grid-template-rows: auto 1fr auto
                width: 100%
                height: 100%
                padding:
                    top: var.$padding__m
                    bottom: var.$padding__l
                background-color: var.$color__white
                box-shadow: var.$shadow__main
                z-index: 10

                & > .swiper
                    box-sizing: border-box
                    width: 100%
                    padding: var.$padding__m 32px calc(#{$nav-button-size} + #{var.$padding__l})
                    display: flex
                    justify-content: center
                    align-items: center

                & > .button-close,
                & > h2
                    padding:
                        left: var.$padding__m
                        right: var.$padding__m

                & > h2
                    text-align: center
                    margin-top: var.$padding__m

            &__img
                display: flex
                justify-content: center
                align-items: center

                & > img
                    position: absolute
                    height: 100%
                    object:
                        fit: contain
                        position: center
                    filter: drop-shadow(0 0 .5rem rgba(var.$color__black, .5))

            &__nav
                position: absolute
                bottom: 0
                display: grid
                grid-template-columns: 1fr auto 1fr
                column-gap: var.$gap__xxl
                align-items: center
                height: $nav-button-size

                .auction-preview__prev,
                .auction-preview__next
                    align-self: flex-start
                    font-size: $nav-button-size
                    margin: unset
                    transition: color var.$transition__main

                    &:hover
                        color: var.$color__orange

                    &:active
                        color: var.$color__grey

                    & > i
                        height: $nav-button-size

                .auction-preview__prev
                    grid-column: 1 / 2
                    grid-row: 1 / 2
                    justify-self: right

                .auction-preview__next
                    grid-column: 3 / 4
                    grid-row: 1 / 2

                .auction-preview__pagination
                    grid-column: 2 / 3
                    grid-row: 1 / 2

                    & > .swiper-pagination-current
                        font-size: 1.75rem
                        font-weight: 600

                .swiper-button-disabled
                    cursor: not-allowed
                    color: var.$color__grey

                    &:hover,
                    & > i:hover
                        color: var.$color__grey

            &__close-bg
                display: none
