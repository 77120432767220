@use "../../main/abstract/variables" as var
@use "../../main/abstract/mixins" as mixin

.menu-enter-active, .menu-enter
    @include mixin.animation(openMenuFromRight, .5s, forwards)

.menu-leave-active, .menu-leave-to
    @include mixin.animation(closeMenuToRight, .5s, forwards)

/* New client types menu transition classes for programmatic transitions */
.open-add-new-client-before-enter
    transform: translateX(100%)

.open-add-new-client-enter
    @include mixin.animation(openMenuFromRight, .5s, forwards)

.close-add-new-client-leave
    @include mixin.animation(closeMenuToRight, .5s, forwards)

.next-add-new-client-leave
    @include mixin.animation(closeMenuToLeft, .5s, forwards)

/* change step for multi client form, programmatic transitions */
.next-multi-form-step-before-enter
    transform: translateX(calc(100% + #{var.$padding__s} * 2))

.next-multi-form-step-enter
    @include mixin.animation(openMultiFormFromRight, .5s, forwards)

.next-multi-form-step-leave
    @include mixin.animation(closeMultiFormToLeft, .5s, forwards)

.prev-multi-form-step-before-enter
    transform: translateX(calc(-100% - #{var.$padding__s} * 2))

.prev-multi-form-step-enter
    @include mixin.animation(openMultiFormFromLeft, .5s, forwards)

.prev-multi-form-step-leave
    @include mixin.animation(closeMultiFormToRight, .5s, forwards)

.add-clients-enter-active
    @include mixin.animation(addClientsUp, .5s, forwards)

.add-clients-leave-active
    @include mixin.animation(addClientsDown, .5s, forwards)
