@use "../../main/abstract/variables" as var
@use "../../main/abstract/mixins" as mixin

.login
    @include mixin.centerX
    box-sizing: border-box
    width: 100%
    max-width: var.$el-size__queen
    padding:
        left: var.$padding__s
        right: var.$padding__s
        top: var.$padding__m

    & > h1
        padding:
            left: var.$padding__s
            right: var.$padding__s
        margin-bottom: var.$gap__xl

    &__form
        display: grid
        grid-auto-flow: row
        row-gap: var.$gap__xl
        box-sizing: border-box
        padding: var.$padding__m var.$padding__s
        border: var.$border__main var.$color__orange

        &--fieldset
            display: grid
            row-gap: var.$gap__l

        &--reset-password
            margin-top: var.$padding__xxs

            & > a
                padding:
                    left: var.$padding__xxs
                    right: var.$padding__xxs

        &--error
            padding:
                left: var.$padding__xxs
                right: var.$padding__xxs

            & > p
                color: var.$color__red
                text-align: center

        &--submit
            margin:
                top: var.$padding__s
                bottom: var.$padding__m

            & > button
                width: 100%

        &--redirect
            padding:
                left: var.$padding__xxs
                right: var.$padding__xxs
            text-align: center
