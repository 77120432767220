@use "../../main/abstract/variables" as var
@use "../abstract/variables" as mobileVar
@use "../../main/abstract/mixins" as mixin

.auction

    &__details
        display: grid
        grid-template-rows: repeat(2, auto)
        column-gap: var.$gap__m
        padding:
            left: var.$padding__s
            right: var.$padding__s
        overflow: hidden
        transition: height var.$transition__slow

        &--header
            grid-row: 2 / 3
            margin:
                top: var.$padding__m
                bottom: calc(#{var.$padding__l} - #{mobileVar.$header})
            padding:
                top: var.$padding__m
                bottom: var.$padding__m

        &--data
            width: 100%
            margin-top: var.$padding__m

            & > li
                $icon-size: 2.25rem
                display: grid
                grid-template-columns: auto 1fr
                align-items: center
                column-gap: var.$gap__s
                padding:
                    top: var.$gap__s
                    bottom: var.$gap__s
                border-top: var.$border__main var.$color__grey

                &:first-child
                    border-top: unset

                & > i
                    height: $icon-size
                    width: $icon-size
                    border: var.$border__main var.$color__black
                    border-radius: 50%

                & > .icon__euro
                    font-size: .9rem

                & > .icon__location
                    font-size: 1.1rem

                & > .icon__ref
                    box-sizing: border-box
                    display: flex
                    align-items: center
                    justify-content: center
                    font-size: .8rem
                    font-weight: 600
                    font-style: normal
                    padding-top: .15rem

        &--swiper
            grid-row: 1 / 2

        & > .auction-img > span::after
            left: -#{var.$padding__s}
            right: -#{var.$padding__s}
            width: calc(100% + #{var.$padding__s} * 2)

    &__main-content

        h4
            margin-bottom: var.$gap__xxs

        &--header
            position: fixed
            inset: 0 0 auto
            background-color: var.$color__light-grey
            overflow: hidden
            transition: height var.$transition__main
            z-index: 100

        &--header-content
            display: grid
            grid-template-columns: mobileVar.$header 1fr auto
            align-items: center
            height: mobileVar.$header

        &--header-button,
        &--header-title,
        &--header-price
            box-sizing: border-box
            height: 100%
            padding:
                top: var.$padding__xxs
                bottom: var.$padding__xxs

        &--header-button
            font-size: 1rem
            padding:
                left: var.$padding__s
                right: var.$padding__s

        &--header-title
            display: flex
            align-items: center
            padding:
                left: var.$padding__s
                right: var.$padding__s
            border:
                left: var.$border__main
                right: var.$border__main
            border-color: var.$color__grey
            overflow: hidden

            & > h2
                font-weight: 400
                font-size: 1.25rem
                max-height: 100%
                width: 100%
                white-space: nowrap
                overflow: hidden
                text-overflow: ellipsis

        &--menu
            position: sticky
            top: 0
            height: max-content
            padding:
                left: var.$padding__s
                right: var.$padding__s
            background-color: var.$color__light-grey
            z-index: 10

            &::before
                content: ""
                position: relative
                left: -#{var.$padding__s}
                display: block
                height: mobileVar.$header
                width: calc(100% + #{var.$padding__s} * 2)
                background-color: var.$color__white

        &--menu-item
            width: max-content

            & > button
                position: relative
                width: inherit
                padding: var.$padding__xs

                &::after
                    content: ""
                    display: block
                    position: absolute
                    bottom: 0
                    left: 0
                    width: 0
                    height: 2px
                    background-color: var.$color__black
                    transition: width var.$transition__main

                &:hover,
                &:disabled

                    &::after
                        width: 100%

            & > span
                display: none

        &--menu-header
            position: relative
            padding: var.$padding__xxs var.$padding__xxs var.$padding__xxxs
            margin-bottom: var.$padding__xs
            background-color: var.$color__light-grey
            border-bottom: var.$border__thick var.$color__black

        &--menu-footer
            position: sticky
            inset: auto 0 0
            z-index: 10
            transition: border var.$transition__main

        &--button-wrapper
            display: grid
            grid-template-columns: repeat(2, 1fr)
            column-gap: var.$padding__s
            align-items: center
            padding:
                left: var.$padding__s
                right: var.$padding__s
            background-color: var.$color__light-grey
            overflow: hidden
            transition: height var.$transition__main

            & > p
                margin-right: auto
                padding:
                    top: var.$padding__xs
                    bottom: var.$padding__m

                @media screen and (max-width: mobileVar.$breakpoint__m)

                    & > i
                        display: none

            & > button
                height: var.$el-size__s
                margin:
                    top: var.$padding__xs
                    bottom: var.$padding__m

        &--data
            display: grid
            row-gap: var.$gap__xxl
            margin-top: var.$padding__l
            padding:
                left: var.$padding__s
                right: var.$padding__s

            & > .container__transition
                overflow: visible

        &--data-list
            display: grid
            grid-template-columns: 1fr
            row-gap: var.$gap__l
            overflow: hidden
            transition: height var.$transition__main

            & > li:nth-child(4)
                border-top: var.$border__main var.$color__grey
                padding-top: var.$gap__l

            & > li:nth-child(5)
                border-bottom: var.$border__main var.$color__grey
                padding-bottom: var.$gap__l

        &--details-annotation
            border: var.$border__main var.$color__orange
            margin-top: var.$padding__m
            padding: var.$padding__xs

            & > h4:nth-of-type(2)
                margin-top: var.$gap__l

            li
                margin-bottom: var.$gap__xxs

                &:last-child
                    margin-bottom: unset

        &--downloads
            margin-top: var.$gap__l

            & > li
                border-top: var.$border__main var.$color__grey

                &:last-child
                    border-bottom: var.$border__main var.$color__grey

        &--download-link
            display: grid
            grid-template-columns: auto 1fr
            padding:
                top: var.$gap__m
                bottom: var.$gap__m
            column-gap: var.$gap__m
            align-items: center
            transition: var.$transition__main

            & > i
                font-size: 1.15rem

            &:hover
                background-color: var.$color__light-grey

                & > .button__light-grey
                    background-color: var.$color__black
                    color: var.$color__white

        &--history
            margin-bottom: unset

            th, td
                border: unset
                padding: var.$gap__s var.$gap__xxs

        &--history-header > tr > th
            text-align: left
            padding-bottom: var.$gap__xl

            &:first-child
                width: 100%

            &:nth-child(2)

                display: none

        &--history-content-header > th
            border-bottom: var.$border__main var.$color__grey

        &--history-content
            text-align: center

            &:nth-child(odd)
                background-color: var.$color__light-grey

            &.my-bid
                background-color: var.$color__light-orange

    &__form-container
        position: fixed
        inset: 0
        height: 100%
        width: 100%
        background-color: var.$color__white
        overflow: auto
        z-index: 100

    &__form
        padding-bottom: var.$padding__l

        &--header
            position: sticky
            top: 0
            display: grid
            grid-template-columns: calc(#{var.$padding__s} + #{var.$el-size__s}) 1fr
            width: 100%
            background-color: var.$color__white
            border-bottom: var.$border__main var.$color__grey
            z-index: 101

            & > h4
                box-sizing: border-box
                padding: var.$padding__xxs var.$padding__s
                @include mixin.centerY
                width: 100%
                white-space: nowrap
                overflow: hidden
                text-overflow: ellipsis

        &--return
            @include mixin.flex(flex-end, center, row)
            margin:
                top: var.$gap__s
                bottom: var.$gap__s
            transition: color var.$transition__main

            & > i
                box-sizing: border-box
                @include mixin.flex(center, center, row)
                height: var.$el-size__s
                width: var.$el-size__s
                border: var.$border__main var.$color__black
                border-radius: 50%
                transition: border-color var.$transition__main

            &:hover
                color: var.$color__orange

                & > i
                    border-color: var.$color__orange

        &--inputs
            display: grid
            row-gap: var.$gap__l
            padding:
                left: var.$padding__s
                right: var.$padding__s
            overflow: hidden
            transition: var.$transition__main

        &--finish-date
            padding:
                top: var.$padding__xs
                bottom: var.$padding__xs
            margin-bottom: var.$gap__l
            border-bottom: var.$border__main var.$color__grey

            & > h4
                margin-bottom: var.$gap__xxs

            & > p
                font-weight: 600
                font-size: 1.25rem
                line-height: 1.25

        &--bid > p
            margin-top: var.$gap__xxs
            padding:
                left: var.$padding__xxs
                right: var.$padding__xxs

        &--terms
            display: grid
            grid-template-columns: auto 1fr
            width: max-content
            max-width: 100%
            @include mixin.centerX
            margin-top: var.$padding__l

            & > .input-error__wrapper
                grid-column: 1 / 3
                margin-top: var.$gap__xxs
                text-align: left

        &--confirm
            display: grid
            grid-template-columns: 1fr
            row-gap: var.$gap__xl
            padding:
                left: var.$padding__s
                right: var.$padding__s
            overflow: hidden
            transition: var.$transition__main

            & > p
                margin-top: var.$padding__m

        &--confirm-resume
            display: grid
            grid-template-columns: 1fr
            row-gap: var.$gap__xs
            margin-top: var.$padding__l
            padding: var.$padding__xs
            border: var.$border__main var.$color__orange

        &--confirm-buttons
            display: grid
            grid-template-columns: repeat(2, 1fr)
            column-gap: var.$gap__m

        &--price-preview
            display: flex

            & > span
                display: inline-block
                overflow: hidden
                transition: height var.$transition__main

    &__annotation
        margin:
            top: var.$padding__l
            bottom: var.$padding__l
        padding:
            left: var.$padding__s
            right: var.$padding__s

        &--content
            margin-top: var.$padding__s
            padding: var.$padding__xs
            border: var.$border__main var.$color__orange

            & > ol
                list-style: none
                padding: unset
                margin: unset

                h3
                    margin-bottom: var.$gap__xxs

                & > li
                    margin-bottom: var.$gap__m

                & > li:last-child
                    margin-bottom: unset
