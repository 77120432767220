@use "../../main/abstract/variables" as var
@use "../abstract/variables" as mobileVar
@use "../../main/abstract/mixins" as mixin

.search-results
    padding:
        left: var.$padding__s
        right: var.$padding__s

    &__aside
        top: var.$padding__m

    &__prices
        height: max-content
        text-align: left
        @include mixin.centerY

    &__results
        display: grid
        grid-auto-flow: row
        row-gap: var.$gap__xl
        margin-top: var.$padding__l

        &--filter
            position: relative
            width: 100%
            max-width: var.$el-size__xxxl
            height: var.$button-size__m
            @include mixin.centerX
            overflow: visible
            z-index: 2

            &.hover
                & > button
                    border-color: var.$color__orange
                    color: var.$color__orange

                    & > i
                        transform: scaleY(-1)

                & > div
                    max-height: var.$el-size__xxl
                    box-shadow: var.$shadow__main

        &--filter-options
            position: relative
            box-sizing: border-box
            display: grid
            grid-template-columns: 1fr auto
            row-gap: var.$gap__s
            width: 100%
            height: var.$button-size__m
            padding: var.$padding__xs var.$padding__s
            border: var.$border__main var.$color__grey
            text-align: left
            background-color: var.$color__white
            transition: var.$transition__main
            z-index: 2

            & > i
                height: 100%
                font-size: .6rem
                transition: transform var.$transition__main

        &--filter-links,
        &--direct-sells__filter-links
            height: max-content
            max-height: 0
            padding:
                left: var.$padding__xs
                right: var.$padding__xs
            background-color: var.$color__white
            overflow: hidden
            transition: var.$transition__main

        &--filter-link
            position: relative
            box-sizing: border-box
            display: grid
            grid-template-columns: auto 1fr
            column-gap: var.$gap__xs
            align-items: center
            height: 2.75rem
            width: 100%
            padding:
                left: var.$padding__xxs
                right: var.$padding__xxs
            border-bottom: var.$border__main var.$color__grey
            transition: border-color var.$transition__main

            &:first-child
                margin-top: var.$padding__xxs

            &:last-child
                margin-bottom: var.$padding__xxs
                border: unset

            & > i
                grid-column: 1 / 2
                grid-row: 1 / 2
                position: relative
                font-size: 1rem
                transition: color var.$transition__main
                z-index: 1

            & > span
                position: relative
                display: flex
                align-items: center
                height: 100%
                z-index: 1

            &::before
                content: ""
                display: block
                position: absolute
                @include mixin.pin(0, auto, 0, 0)
                height: 100%
                width: 0
                background-color: var.$color__light-orange
                transition: var.$transition__main
                z-index: 0

            &:hover::before
                width: 100%

            &:active::before
                box-shadow: var.$shadow__small-inset

        &--item
            box-sizing: border-box
            display: grid
            grid-template-rows: var.$el-size__xxxl auto
            height: max-content
            padding: var.$padding__xs
            border: var.$border__main var.$color__grey
            transition: var.$transition__main

            &:hover
                background-color: var.$color__black
                color: var.$color__white

            &:active
                background-color: var.$color__dark-grey

        &--item-img
            background-position: center
            background-size: cover
            background-repeat: no-repeat

        &--item-info
            display: grid
            grid-auto-flow: row
            row-gap: var.$gap__s
            height: max-content
            margin-top: var.$gap__s

        &--item-name
            margin-top: var.$gap__xs

            & > p
                margin-top: var.$gap__xs

        &--item-features > span
            margin-left: var.$gap__s

        &--item-numbers
            display: grid
            grid-auto-flow: row
            row-gap: var.$gap__xxl
            margin-top: var.$gap__s

        &--item-price
            display: grid
            grid-auto-flow: row
            row-gap: var.$gap__xxs

        &--direct-sells
            display: grid
            grid-template-columns: 1fr
            gap: var.$gap__m

            @media screen and (min-width: mobileVar.$breakpoint__s)
                grid-template-columns: repeat(2, 1fr)

            & > article
                display: grid

            &__item
                display: grid
                grid-template-rows: var.$el-size__xxl 1fr
                row-gap: var.$gap__s
                padding: var.$padding__xxs var.$padding__xxs var.$padding__xs
                box-shadow: var.$shadow__main, var.$shadow__main-inset-hidden
                transition: var.$transition__main

                & > section
                    transition: var.$transition__main

                &:hover
                    background-color: var.$color__light-orange

                &:active
                    box-shadow: var.$shadow__main-hidden, var.$shadow__main-inset
                    background-color: var.$color__pale-orange

            &__item-img
                background-position: center
                background-size: cover
                background-repeat: no-repeat

            &__item-info
                display: grid
                grid-template-rows: repeat(2, auto) 1fr
                row-gap: var.$gap__s

            &__item-name
                margin-bottom: var.$gap__m

                & > p
                    margin-top: var.$gap__xs

            .timer-simple
                margin-top: auto

        &--empty
            margin-top: var.$gap__m

            & > p
                margin-top: var.$gap__m
