@use "../../main/abstract/variables" as var

.timer,
.timer-simple
    display: grid
    grid-auto-flow: column
    font-size: 1rem
    font-weight: 400
    transition: color var.$transition__main

    &__content
        display: block
        overflow: hidden
        transition: width var.$transition__main

        & > time,
        & > span
            display: block
            width: max-content

.timer
    box-sizing: border-box
    width: max-content
    align-items: center
    margin:
        top: auto
        left: auto

    .icon__clock
        margin-right: var.$gap__xs
        font-size: 1.25rem
