@use "../../main/abstract/variables" as var

.direct-sells-single

    &__title
        margin-top: var.$gap__m

    &__file-list-wrapper
        display: block
        transition: height var.$transition__main

        .input-label__file--list
            margin:
                top: var.$gap__m
                bottom: var.$gap__m
            border-bottom: var.$border__main var.$color__grey

    .auction__main-content--data-list

        & > li:nth-child(3)
            border-top: var.$border__main var.$color__grey
            padding-top: var.$gap__l

        & > li:nth-child(4)
            border-bottom: var.$border__main var.$color__grey
            padding-bottom: var.$gap__l

        & > li:nth-child(4)
            border-top: unset
            padding-top: unset

        & > li:nth-child(5)
            border-bottom: unset
            padding-bottom: unset
