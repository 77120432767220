@use "../../main/abstract/variables" as var
@use "../abstract/variables" as mobileVar
@use "../../main/abstract/mixins" as mixin

.auction-preview
    $nav-button-size: 3.25rem
    $nav-height: 7.5rem
    display: grid
    grid-template-columns: 1fr
    grid-template-rows: auto

    &__container
        grid-column: 1 / 2
        grid-row: 1 / 2
        display: grid
        grid-template-rows: calc(#{var.$el-size__xxxl} + #{var.$padding__m} + #{var.$padding__s}) 1fr

        &::before
            content: ""
            display: block
            grid-column: 1 / 2
            grid-row: 2 / 3
            height: 100%
            width: 100%
            background-color: var.$color__black

    &__swiper
        display: block
        grid-column: 1 / 2
        grid-row: 1 / 3
        box-sizing: border-box
        width: 100%
        height: max-content
        padding: var.$padding__m var.$padding__s $nav-height

    &__swiper-wrapper
        height: 100%

    &__item
        width: 100%
        height: auto
        max-width: var.$swiper-item__m
        margin:
            right: var.$gap__m
            left: var.$gap__m

        & > a,
        & > button
            box-sizing: border-box
            display: block
            width: 100%
            height: 100%
            padding: var.$padding__s
            background-color: var.$color__white
            box-shadow: var.$shadow__main
            border: var.$border__thin var.$color__white
            transition: var.$transition__main

            &:hover
                box-shadow: var.$shadow__main-hidden
                color: var.$color__white
                background-color: var.$color__black
                border-color: var.$color__dark-grey

            &:active
                box-shadow: var.$shadow__main-inset-dark
                color: var.$color__white
                background-color: var.$color__dark-grey

            & > .auction-preview__group-title
                margin-bottom: var.$gap__xl

                & > p,
                .auction-preview__resume,
                .auction-preview__resume > p:not(p:first-child)
                    margin-top: var.$gap__xs

            .auction-preview__resume
                display: block

                strong
                    font-weight: 800

    &__item-img
        width: 100%
        margin-bottom: var.$padding__s

        & > img
            width: inherit
            height: var.$el-size__xxxl
            object-fit: cover
            object-position: center
            margin-bottom: var.$gap__s

        & > figcaption
            text-align: left

    &__swiper-controls
        position: absolute
        @include mixin.pin(auto, 0, 0, 0)
        display: grid
        grid-template-columns: 1fr auto 1fr
        column-gap: var.$gap__l
        align-items: center
        height: $nav-height
        max-width: var.$swiper-item__m
        padding:
            left: var.$padding__s
            right: var.$padding__s
        @include mixin.centerX
        color: var.$color__white
        z-index: 2

        & > .swiper-button-disabled
            cursor: not-allowed
            color: var.$color__dark-grey

            &:hover,
            &:active
                color: var.$color__dark-grey

    &__prev,
    &__next
        font-size: $nav-button-size
        transition: color var.$transition__main

        &:hover
            color: var.$color__orange

        &:active
            color: var.$color__grey

        & > i
            height: $nav-button-size

    &__prev
        grid-column: 1 / 2
        grid-row: 1 / 2

    &__next
        grid-column: 3 / 4
        grid-row: 1 / 2
        margin-left: auto

    &__pagination
        grid-column: 2 / 3
        grid-row: 1 / 2

        & > .swiper-pagination-current
            font-size: 1.75rem
            font-weight: 600

    &__empty
        box-sizing: border-box
        max-width: var.$swiper-item__m
        padding: var.$padding__s
        @include mixin.centerX
        background-color: var.$color__white
        box-shadow: var.$shadow__main

        &--content
            min-height: var.$el-size__xxxl

            & > h2
                text-transform: uppercase

            & > p:first-of-type
                margin:
                    top: var.$gap__xl
                    bottom: var.$gap__xs
