@use "../../main/abstract/variables" as var

.auction-group

    &__main
        display: grid
        row-gap: var.$gap__xl

        &--header
            padding:
                top: var.$padding__l
                left: var.$padding__s
                right: var.$padding__s

            & > h1
                margin-bottom: var.$gap__s

            & > p:last-child
                display: inline-grid
                grid-template-columns: auto 1fr
                column-gap: var.$gap__s
                margin-top: var.$gap__xl

                & > i
                    font-size: 2rem

        &--img
            display: grid
            grid-template-rows: repeat(2, 2fr) var.$padding__xl

            & > img
                box-sizing: border-box
                grid-row: 1 / 3
                grid-column: 1 / 2
                height: auto
                width: 100%
                object-position: center
                object-fit: cover
                padding:
                    left: var.$padding__s
                    right: var.$padding__s

            & > span
                display: block
                position: relative
                grid-row: 2 / 4
                grid-column: 1 / 2
                background-color: var.$color__black
                z-index: -1

    &__properties
        margin:
            top: var.$padding__l
            bottom: var.$padding__l

        &--header
            margin-bottom: var.$padding__m
            padding:
                left: var.$padding__s
                right: var.$padding__s
            text-align: center
