@use "../../main/abstract/variables" as var
@use "../abstract/variables" as mobileVar
@use "../../main/abstract/mixins" as mixin

.footer
    position: relative
    margin-top: var.$padding__l
    padding: var.$padding__s var.$padding__s var.$padding__l
    display: grid
    grid-template-columns: 1fr
    row-gap: var.$gap__xl

    @media screen and (min-width: mobileVar.$breakpoint__m)
        grid-template-columns: repeat(2, 1fr)
        column-gap: var.$gap__m

    &::before
        position: absolute
        top: 0
        left: var.$padding__s
        content: ""
        display: block
        height: 1px
        width: calc(100% - #{var.$padding__s} * 2)
        background-color: var.$color__medium-grey

    &__list-properties,
    &__list-address,
    &__list-info

        h4
            margin-bottom: var.$gap__s

        p, li
            margin-top: var.$gap__xs

        a
            transition: color var.$transition__main

            &:hover
                color: var.$color__orange

            &:active
                color: var.$color__medium-grey

    &__list-info-group
        display: grid
        grid-template-columns: 1fr
        grid-template-rows: repeat(2, auto)
        row-gap: var.$gap__xl
        height: max-content

    &__list-address

        address
            font-style: normal

    &__email
        margin-bottom: var.$gap__m

        & > a
            font-size: .83rem
            vertical-align: -0.09rem

    &__list-img
        $img-size: 2.5rem
        display: grid
        grid-template-columns: 1fr
        row-gap: var.$gap__m
        height: max-content

        @media screen and (min-width: mobileVar.$breakpoint__m)
            grid-column: 1 / 3

        & > article
            display: grid
            grid-template-columns: auto 1fr
            column-gap: var.$gap__m
            height: max-content

            & > img
                width: $img-size

            & > p
                height: max-content
                @include mixin.centerY
