@use "../../main/abstract/variables" as var

// Sizes
$header: calc(#{var.$padding__xs} * 2 + 2.75rem + 1px)
$menu-height: 1rem
$menu-width: 1.5rem

// Breakpoints
$breakpoint__m: 650px
$breakpoint__s: 500px
