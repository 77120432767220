@use "../../main/abstract/variables" as var

.leagal-texts
    display: grid
    row-gap: var.$padding__l
    padding:
        top: var.$padding__l
        left: var.$padding__m
        right: var.$padding__m

    & > h1
        text-align: center

    &__section
        display: grid
        row-gap: var.$gap__xs

        & > h2
            margin-bottom: var.$gap__xs

    .icon__email
        font-size: .85rem
