@use "../../main/abstract/variables" as var
@use "../../main/abstract/mixins" as mixin

.not-found
    display: grid
    grid-auto-flow: row
    row-gap: var.$gap__xxl

    &__info
        padding:
            left: var.$padding__m
            right: var.$padding__m
        text-align: center

        & > div
            margin-top: var.$gap__xxl
            @include mixin.centerX
            max-width: var.$el-size__xxxl

    &__img
        display: grid
        grid-template-rows: repeat(6, var.$el-size__l)
        grid-template-columns: var.$padding__m 1fr var.$padding__m

        &--photo
            position: relative
            display: block
            @include mixin.bgImg('../img/404.webp', bottom, cover, no-repeat)
            grid-column: 2 / 3
            grid-row: 1 / 5

        &--bg
            display: block
            height: 100%
            width: 100%
            grid-row: 2 / 7
            grid-column: 1 / 4
            background-color: var.$color__black
