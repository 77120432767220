@use "../../main/abstract/variables" as var
@use "../abstract/variables" as mobileVar

.m-top-margin__main
    margin-top: mobileVar.$header

.border-top-grey
    border-top: var.$border__main var.$color__grey

.border-bottom-grey__before::before
    border-bottom: var.$border__main var.$color__grey
